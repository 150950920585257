<template>
  <v-app id="`${backend ? 'backend' : 'frontend'}-uploader`">
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-container fluid>
        <v-row no-gutters>
          <v-select
            v-model=provider
            :items=providers
            item-text=name
            item-value=code
            :label=labels.provider
            :prepend-icon="'$provider'"
            :rules="[rules.required]"
          />
        </v-row>
        <v-row no-gutters v-if="backend">
          <v-text-field
            v-model=csha
            :hint=labels.cshaHint
            :label=labels.csha
            :prepend-icon="'$csha'"
            :rules="[rules.csha]"
          />
        </v-row>
        <v-row no-gutters>
          <v-file-input
            v-model=file
            show-size
            truncate-length="50"
            :label="backend ? labels.backendFile : labels.frontendFile"
            :rules="[
              rules.required,
              backend ? rules.backend_file : rules.frontend_file
            ]"
          />
        </v-row>
        <v-row>
          <v-col cols=11 class="align-center">
            <v-btn
              :loading="uploading"
              :disabled="uploading || !valid"
              color="primary"
              class="ma-2 white--text"
              @click.prevent.stop="upload"
            >
              {{ labels.upload }}
              <v-icon right dark v-text="'$upload'" />
            </v-btn>
          </v-col>
          <v-col cols=1 class="align-center">
            <v-btn
              :disabled="uploading"
              color="primary"
              fab
              @click="showDialog"
            >
              <v-icon v-text="'$info'" />
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-spacer />

    <v-row v-if=uploading>
      <v-progress-linear
        v-model="percentCompleted"
        color="primary"
        height="25"
        striped
      />
    </v-row>
    <v-dialog
      v-model="openDialog"
      heigth="75vh"
      scrollable
      width="75vw"
      class="dialog"
    >
      <v-data-table
        :footer-props="tablePagination"
        :headers=headers
        :items=versions
        :items-per-page.sync=itemsPerPage
        :page.sync=page
        :server-items-length="versionCount"
        :sort-by.sync=sortBy
        :sort-desc.sync=desc
        class="flex"
      >
        <template v-slot:item.provider="{ value }">
          <v-chip dark color="primary">{{ value }}</v-chip>
        </template>
      </v-data-table>
    </v-dialog>
  </v-app>
</template>

<script>
// import axios from 'axios';
import {
  first,
  get,
  isArray,
  isEmpty,
} from 'lodash';
import { mapActions, mapState } from 'vuex';
import { labels } from '@/assets/texts.json';
import { formUpload } from '@/helpers';

export default {
  name: 'uploader',
  props: {
    backend: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    // Check in vuex if providers were already fetched
    if (isEmpty(this.providers)) this.fetchProviders();
  },
  computed: {
    ...mapState('fetch', [
      'backendVersionCount',
      'backendVersions',
      'frontendVersionCount',
      'frontendVersions',
      'providers',
    ]),
    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Version',
          value: 'version',
          sortable: true,
        },
        { // TODO: check in backend why we return in different ways
          text: 'Provider',
          value: this.backend ? 'provider' : 'provider.name',
          sortable: true,
        },
        { // TODO: check in backend why we return in different ways
          text: 'Date',
          value: this.backend ? 'modified' : 'created',
          sortable: true,
        },
      ];
    },
    rules() {
      return {
        csha: (value) => {
          const pattern = /\b[0-9A-Fa-f]{40}\b/g;
          return pattern.test(value) || this.labels.cshaHint;
        },
        required: (value) => !!value || this.labels.required,
        backend_file: (value) => {
          const pattern = /\w+-(\d+\.){4}zip/g;
          return pattern.test(value.name) || this.labels.zipFileHint;
        },
        frontend_file: (value) => {
          const pattern = /[A-Z0-9]+-(\d+\.){4}front.zip/g;
          return pattern.test(value.name) || this.labels.zipFrontendHint;
        },
      };
    },
    versionCount() {
      return this.backend ? this.backendVersionCount : this.frontendVersionCount;
    },
    versions() {
      return this.backend ? this.backendVersions : this.frontendVersions;
    },
  },
  data: () => ({
    csha: '',
    desc: true,
    file: [],
    itemsPerPage: 10,
    labels,
    page: 1,
    percentCompleted: 0,
    provider: null,
    openDialog: false,
    sortBy: 'modified',
    uploading: false,
    tablePagination: { 'items-per-page-options': [10, 20, 50, -1] },
    valid: false,
  }),
  methods: {
    ...mapActions({
      displayMsg: 'shared/displayMsg',
      errorMsg: 'shared/errorMsg',
      fetchProviders: 'fetch/fetchProviders',
      fetchVersions: 'fetch/fetchVersions',
    }),
    getVersions() {
      const params = {
        items_per_page: this.itemsPerPage,
        page: this.page,
        sort_by: isArray(this.sortBy) ? first(this.sortBy) : this.sortBy,
        desc: isArray(this.desc) ? first(this.desc) : this.desc,
        ...(this.backend && { retired: false }),
      };
      return this.fetchVersions({ backend: this.backend, params });
    },
    showDialog() {
      this.openDialog = true;
      this.getVersions();
    },
    upload: function upload() {
      this.$refs.form.validate();
      this.uploading = true;
      const config = {
        onUploadProgress: (progressEvent) => {
          this.percentCompleted = (
            (progressEvent.loaded * 100) / get(this.file, 'size')
          );
        },
        headers: {
          'Content-Type': 'application/json; application/octet-stream',
        },
        responseType: 'json',
      };
      const form = new FormData();
      form.append('provider', this.provider);
      form.append(this.backend ? 'game_back' : 'game_front', this.file);
      if (this.backend) { // backend only
        form.append('game_csha', this.csha);
      }
      formUpload({
        url: `/v2/upload/${this.backend ? 'game_backend' : 'game_frontend'}`,
        config,
        form,
      }).then((response) => {
        this.displayMsg(response.message);
      }).catch((error) => {
        this.errorMsg(error.response.error);
        console.log(error.response);
      }).finally(() => { this.uploading = false; });
    },
  },
  watch: {
    desc: function desc() {
      this.getVersions();
    },
    itemsPerPage: function itemsPerPage() {
      this.getVersions();
      this.page = 1;
    },
    page: function page() {
      this.getVersions();
    },
    sortBy: function sortBy() {
      this.getVersions();
    },
  },
};

</script>

<style>
  .dialog {
    z-index: 9999;
  }
  .v-data-table-header {
    background-color: var(--v-primary-base);
    color: white;
  }
  th {
    color: white !important;
  }
</style>
